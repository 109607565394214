<template>
  <div>
    <div :id="id" class="provinceCharts"></div>
    <div v-if="dialogVisible" class="tangkuang select_Img"
      :style="{ top: flagOption.top + 'px', left: flagOption.left + 'px' }" @mousedown.stop="mouseDown">
      <div class="top">
        {{ city }} ({{ communityList.length }})
      </div>
      <div class="body">
        <div class="li" v-for="(item, index) in communityList" :key="index">
          <a :href="'/#/?id=' + item.deptId" target="_top">
            <div class="text">
              <img src="https://f.jsttwy.cn/public/fromicon.png" alt="">
              {{ item.deptName }}
            </div>
          </a>
        </div>
      </div>
    </div>
    <div @click="goBack()" style="color: white;position: absolute; bottom: 10%;left: 84%; cursor: pointer;">返回</div>
  </div>
</template>

<script>
import { Getprovince } from '@/api/bs'
export default {
  name: 'province',
  data() {
    return {
      city:"",
      flagOption: {
        top: 130,
        left: 40
      },
      dialogVisible: false,
      id: 'echarts_' + new Date().getTime() + Math.floor(Math.random() * 1000),
      echartObj: null,
      option: {
        title: {
          text: '',
          top: '8%',
          left: '8%',
          textStyle: {
            fontSize: 14,
            fontWeight: 300,
            color: '#000'
          }
        },
        tooltip: {
          padding: 0,
          // 数据格式化
          formatter: function (params) {
            return params.name +(params.value[2]? '：'+params.value[2]:'')
          }
        },
        legend: {
          orient: 'vertical',
          top: '9%',
          left: '5%',
          icon: 'circle',
          data: [],
          selectedMode: 'single',
          selected: {},
          itemWidth: 12,
          itemHeight: 12,
          itemGap: 30,
          inactiveColor: '#b6d7ff',
          textStyle: {
            color: '#ec808d',
            fontSize: 14,
            fontWeight: 300,
            padding: [0, 0, 0, 15]
          }
        },
        geo: {
          layoutCenter: ['50%', '50%'],//位置
          layoutSize: '70%',//大小
          show: true,
          roam: true,
          label: {
            normal: {
              show: true,
              fontSize: 12,
              color: '#fff'
            },
            emphasis: {
              show: true,
              color: 'fff',
            }
          },
          itemStyle: {
            // 边框颜色
            normal: {
              borderColor: 'rgba(62, 127, 247, 1)',
              borderWidth: 1,
              areaColor: {
                type: 'radial',
                x: 0.5,
                y: 0.5,
                r: 0.8,
                colorStops: [{
                  offset: 0,
                  color: 'rgba(62, 127, 247, 0)' // 0% 处的颜色
                }, {
                  offset: 1,
                  color: 'rgba(62, 127, 247, 0.2)' // 100% 处的颜色
                }],
                globalCoord: false // 缺省为 false
              },
              // shadowColor: 'rgba(128, 217, 248, 1)',
              shadowColor: 'rgba(62, 127, 247, 0.4)',
              shadowOffsetX: -2,
              shadowOffsetY: 2,
              shadowBlur: 100
            },
            emphasis: {
              areaColor: '#389BB7',
              borderWidth: 0
            }
          }
        },
        series: [
          {
            name: '省份数据',
            type: 'map',
            geoIndex: 0, // 不可缺少，否则无tooltip 指示效果
            data: []
          }
        ],
        provinceJSON: {},
      },
      communityList: [
        {deptId: 207, deptName: "蓝天水岸花园"},
        {deptId: 222, deptName: "蓝天慧融花园"}
      ],
      toolTipData:[
        { value: 2, name: "南京市" },
      ],
    }
  },
  mounted() {
    this.resizeListener();
    this.loadData()
  },
  methods: {
    async loadData() {
      console.log(this.$route.query);
      const province = this.$route.query.province
      const res = await Getprovince({province:province})
      if (res.code === 200 && res.data && res.data.length) {
        this.toolTipData=res.data
      }
      this.initDate()
    },
     initDate() {
      const provinceName = this.$route.query.provinceName
      const province = this.$route.query.province
      this.provinceName = provinceName
      this.provinceJSON = require('../../utils/省份数据/json(省份)/' + provinceName)
      this.echartObj = this.$echarts.init(document.getElementById(this.id))
      this.$echarts.registerMap(province, this.provinceJSON)
      var mapFeatures = this.$echarts.getMap(province).geoJson.features;
      this.option.title.text = province;
      this.option.geo.map = province
      var geoCoordMap = { };
      mapFeatures.forEach(function (v) {
        // 地区名称
        if (v.properties.name) {
          var name = v.properties.name;
          // 地区经纬度
          geoCoordMap[name] = v.properties.cp;
        }
      });
      var toolTipData = this.toolTipData
      var convertData = function (data) {
        var res = [];
        for (var i = 0; i < data.length; i++) {
          var geoCoord = geoCoordMap[data[i].name];
          if (geoCoord) {
            res.push({
              name: data[i].name,
              value: geoCoord.concat(data[i].value),
              values:geoCoord.concat(data[i].value)[geoCoord.concat(data[i].value).legend-1]
            });
          }
        }
        return res;
      };
      this.option.series.push({
        name: 'Top 5',
        type: 'effectScatter',
        coordinateSystem: 'geo',
        data: convertData(toolTipData),
        symbolSize: function () {
          return 15;
        },
        
        showEffectOn: 'render',
        rippleEffect: {
          brushType: 'stroke',
        },
        hoverAnimation: true,
        label: {
          normal: {
            formatter: '{b}',
            position: 'right',
            show: true,
            color: "#fff"
          },
        },
        itemStyle: {
          normal: {
            color: '#1DE9B6',
            shadowBlur: 10,
            shadowColor: '#1DE9B6',
          },
        },
        zlevel: 1,
      })
      this.echartObj.setOption(this.option);
      this.echartObj.on("click", async(params) => {
        this.dialogVisible = false
        
        const city = this.toolTipData.find(item => item.name === params.name);
        if (city && city.value) {
          const res = await Getprovince({ province: this.$route.query.province, city: params.name })
          if (res.code === 200 && res.data && res.data.length) {
            this.communityList = res.data
            this.city = params.name
            this.dialogVisible = true
          } else {
            this.$message('此地区暂无小区');
          }
        } else {
          this.$message('此地区暂无小区');
        }

        // this.$router.push({
        //   path: "/city",
        //   query: { city: params.name },
        // });
      });
    },
    resizeListener() {
      window.addEventListener('resize', () => {
        if (this.echartObj && this.echartObj.resize) {
          this.echartObj.resize()
        }
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    goto(item) {
      window.location.href = "/?id=" + item.id;
      window.parent.location.href = "/?id=" + item.id;//让父页面一起跳转
    },

    mouseDown(e) {
      const pos = { ...this.flagOption };//原来的图标位置
      const startY = e.clientY;
      const startX = e.clientX;
      const startTop = Number(pos.top);
      const startLeft = Number(pos.left);
      const move = (moveEvent) => {
        const curX = moveEvent.clientX;
        const curY = moveEvent.clientY;
        this.flagOption.top = curY - startY + startTop;
        this.flagOption.left = curX - startX + startLeft;
      };

      const up = () => {
        document.removeEventListener("mousemove", move);
        document.removeEventListener("mouseup", up);
      };
      document.addEventListener("mousemove", move);
      document.addEventListener("mouseup", up);
    },
  }
}
</script>
<style lang="scss">
.provinceCharts {
  height: 700px;
  width: 788px;
  margin: auto;
}

.tangkuang {
  width: 320px;
  height: 420px;
  position: absolute;
  top: 20%;
  left: 10%;
  background-image: url("https://f.jsttwy.cn/public/pop-up.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;

  .top {
    width: 100%;
    height: 80px;
    line-height: 80px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #CCCCCC;
    font-size: 20px;
  }

  .body {
    height: 100%;
    height: 320px;
    overflow-y: auto;
    padding: 10px 0 0 60px;
    box-sizing: border-box;
    .li {
      margin: 0 0 40px;
      cursor: pointer;
      .text {
        position: relative;
        border: 1px solid #12FBFE;
        color: #CCCCCC;
        display: inline-block;
        padding: 5px 10px 5px 40px;
        border-radius: 20px;
        img {
          width: 50px;
          height: 50px;
          position: absolute;
          top: -10px;
          left: -12px;
        }
      }
    }

  }

  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }

  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    border-radius: 999px;
    border: 5px solid transparent;
  }



  ::-webkit-scrollbar-thumb {
    min-height: 30px;
    background-clip: content-box;
    box-shadow: 0 0 0 5px rgba(0, 0, 0, 0.3) inset;
  }


}
</style>
